// Auto update layout
(function() {
  window.layoutHelpers.setAutoUpdate(true);
  window.attachMaterialRippleOnLoad();
})();

// Collapse menu
(function() {
  if ($('#layout-sidenav').hasClass('sidenav-horizontal') || window.layoutHelpers.isSmallScreen()) {
    return;
  }

  try {
    window.layoutHelpers.setCollapsed(
      localStorage.getItem('layoutCollapsed') === 'true',
      false
    );
  } catch (e) {}
})();

$(function() {
  // Initialize sidenav
  $('#layout-sidenav').each(function() {
    new SideNav(this, {
      orientation: $(this).hasClass('sidenav-horizontal') ? 'horizontal' : 'vertical'
    });
  });

  // Initialize sidenav togglers
  $('body').on('click', '.layout-sidenav-toggle', function(e) {
    e.preventDefault();
    window.layoutHelpers.toggleCollapsed();
    if (!window.layoutHelpers.isSmallScreen()) {
      try { localStorage.setItem('layoutCollapsed', String(window.layoutHelpers.isCollapsed())); } catch (e) {}
    }
  });

  if ($('html').attr('dir') === 'rtl') {
    $('#layout-navbar .dropdown-menu').toggleClass('dropdown-menu-right');
  }
});

// $('body').append('' +
//     '<div class="fixed-button active">' + '<a href="https://codedthemes.com/item/bhumlu-bootstrap-admin-template/" target="_blank" class="btn btn-md btn-primary">' + '<i class="fa fa-shopping-cart" aria-hidden="true"></i> Buy now' +
//     '</a> ' + '</div>' + '');
// var $window = $(window);
// var nav = $('.fixed-button');
// $window.scroll(function() {
//     if ($window.scrollTop() >= 200) {
//         nav.addClass('active');
//     } else {
//         nav.removeClass('active');
//     }
// });


